window.lhnJsSdkInit = function () {

	lhnJsSdk.setup = {
		application_id: lhnchat.application_id,
		application_secret: lhnchat.application_secret
	};

	lhnJsSdk.controls = [{
		type: "hoc",
		id: lhnchat.widget_id
	}];

	lhnJsSdk.options = lhnchat.chat_options;

	if ( lhnchat.user.display_name ) {
		lhnJsSdk.options.custom1 = lhnchat.user.display_name;
	}

	if ( lhnchat.user.email ) {
		lhnJsSdk.options.custom2 = lhnchat.user.email;
	}

	if ( lhnchat.user.cart ) {
		lhnJsSdk.options.custom3 = lhnchat.user.cart;
	}

	lhnJsSdk.dictionary = lhnchat.chat_dictionary;

};

(function (d, s) {
	var newjs, lhnjs = d.getElementsByTagName(s)[0];
	newjs = d.createElement(s);
	newjs.src = "https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js";
	lhnjs.parentNode.insertBefore(newjs, lhnjs);
}(document, "script"));
